const state = () => ({
  currentRoom: 'city',
  previousRooms: [],
})

// getters
const getters = {
  currentRoom: (state) => state.currentRoom,
  previousRoom: (state) => {
    let room = null;
    if (state.previousRooms.length) {
      room = state.previousRooms.slice(-1)
    }
    return room
  },
}

// actions
const actions = {
  changeRoom ({ commit, state }, room) {
    if (state.room !== room) {
      commit('setRoom', {room})
    }
  },
  gotoPreviousRoom ({ commit, state }) {
    let room = state.previousRooms.pop()
    commit('setRoom', { room: room[0], previous: true })
  },
}

// mutations
const mutations = {
  setRoom (state, { room, previous = false }) {
    if (!previous)
      state.previousRooms.push([state.currentRoom])
    state.currentRoom = room
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}