const state = () => ({
  bids: [],
  status: ['created', 'accepted', 'counterOffer', 'secondBid', 'secondCounterOffer', 'thirdBid', 'canceled', 'rejected'],
})

// getters
const getters = {
  getBid: (state) => (uri) => state.bids.find(bid => bid['@id'] === uri),
  getBidsForCompany: (state) => (uri) => state.bids.filter(bid => bid['company'] === uri),
  getActiveBidForCompany: (state) => (uri) => {
    return state.bids.find(bid => (bid['company'] === uri && ([1,3,4,5,6].includes(bid['status']))))
  },
  getStatus: (state) => (id) => state.status[id - 1],
  getWinningBid: (state) => (uri) => state.bids.find(bid => {
    return (bid['company'] === uri && bid['status'] === 2)
  }),
  getCurrentAmount: () => (bid) => {
    if (bid.thirdBidAmount) {
      return bid.thirdBidAmount
    } else if (bid.secondBidAmount) {
      return bid.secondBidAmount
    } else {
      return bid.amount
    }
  }
}

// actions
const actions = {
  async fetchBids({ commit, rootState }) {
    try {
      const data = await fetch(
        process.env.VUE_APP_API_URL + '/bids', {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + rootState.user.token,
          },
        }
      )
      const result = await data.json()
      commit("setBids", result['hydra:member'])
    } catch (error) {
      console.log(error)
    }
  },
  async fetchBid({ rootState }, bidId) {
    try {
      const data = await fetch(
        process.env.VUE_APP_API_URL + '/bids/' + bidId, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + rootState.user.token,
          },
        }
      )
      return await data.json()
    } catch (error) {
      console.log(error)
    }
  },
}

// mutations
const mutations = {
  setBids(state, bids) {
    state.bids = bids
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}