<script setup>
import RoomComponent from './RoomComponent.vue'
</script>

<template>
  <div class="h-full py-6 md:py-10 px-5 flex flex-col">
    <h1 class="text-2xl text-center uppercase">{{ city(user.currentCity).name }}</h1>
    <h1 class="text-5xl text-center mb-2 md:mb-4 font-medium">{{ airportByCity(user.currentCity).name ?? '' }}</h1>
    <RoomComponent>
      <div v-for="item in airportByCity(user.currentCity).destinations" :key="item" class="text-center">
        <button class="my-1" @click="setCurrentCity(airport(item).city)">{{ airport(item).name }}</button>
      </div>
    </RoomComponent>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters('user', {
      user: 'getUser',
    }),
    ...mapGetters('city', {
      city: 'getCity',
    }),
    ...mapGetters('airport', {
      airport: 'getAirport',
    }),
    ...mapGetters('airport', {
      airportByCity: 'getAirportByCity',
    }),
  },
  methods: {
    ...mapActions('user', [
      'setCurrentCity'
    ]),
  },
}
</script>
