const state = () => ({
  industries: [],
})

// getters
const getters = {
  getIndustry: (state) => (uri) => state.industries.find(industries => industries['@id'] === uri),
}

// actions
const actions = {
  async fetchIndustries({ commit, rootState }) {
    try {
      const data = await fetch(
        process.env.VUE_APP_API_URL + "/industries", {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + rootState.user.token,
          },
        }
      )
      const result = await data.json()
      commit("setIndustries", result['hydra:member'])
    } catch (error) {
      console.log(error)
    }
  },
}

// mutations
const mutations = {
  setIndustries(state, industries) {
    state.industries = industries
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}