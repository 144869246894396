<script setup>
import { useStore } from 'vuex'
import RoomComponent from './RoomComponent.vue'
const store = useStore()

const user = store.getters['user/getUser']
const city = store.getters['city/getCity']

store.state.background = ('cities/' + city(user.currentCity).background)
</script>

<template>
  <div class="h-full py-6 md:py-10 px-5 flex flex-col">
    <h1 class="text-2xl text-center uppercase">Welcome to</h1>
    <h1 class="text-5xl mb-2 md:mb-4 text-center font-medium">{{ city(user.currentCity).name }}</h1>
    <RoomComponent>
      <div class="text-center">
        <p>You have connections at the following investment firms</p>
        <div v-for="item in investmentBanksByCity(user.currentCity)" :key="item">
          <button class="py-2 px-4 my-2" @click="setCurrentInvestmentBank(item['@id']);changeRoom('investment-bank')">{{ item.name }}</button>
        </div>
      </div>
    </RoomComponent>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters('investmentBank', {
      investmentBank: 'getInvestmentBank',
      investmentBanksByCity: 'getInvestmentBanksByCity',
    }),
  },
  methods: {
    ...mapActions('room', [
      'changeRoom'
    ]),
    ...mapMutations('investmentBank', [
      'setCurrentInvestmentBank'
    ]),
  },
}
</script>