<script setup>
import { useStore } from 'vuex'
import RoomComponent from './RoomComponent.vue'
const store = useStore()

const user =  store.state.user.user
const companies = store.getters['company/getMyCompanies'].call(user['@id'])
const getWinningBid = store.getters['bid/getWinningBid']
</script>

<template>
  <div class="h-full py-6 md:py-10 px-5 flex flex-col">
    <h1 class="text-5xl text-center mb-2 md:mb-4 font-medium">Portfolio</h1>
    <RoomComponent>
      <table v-for="company in companies" :key="company" class="w-full text-left mb-2">
        <tbody>
        <tr>
          <th class="text-xl" colspan="2">{{ company.name }}</th>
        </tr>
        <tr>
          <th class="font-medium w-[50%]">Sales</th>
          <td>&euro; {{ format(company.sales) }}</td>
        </tr>
        <tr>
          <th class="font-medium">EBITDA</th>
          <td>&euro; {{ format(company.ebitda) }}</td>
        </tr>
        <tr v-if="getWinningBid(company['@id'])">
          <th class="font-medium">Winning bid</th>
          <td>
            &euro;
            <span v-if="getWinningBid(company['@id']).thirdBidAmount">{{ format(getWinningBid(company['@id']).thirdBidAmount) }}</span>
            <span v-else-if="getWinningBid(company['@id']).secondBidAmount">{{ format(getWinningBid(company['@id']).secondBidAmount) }}</span>
            <span v-else>{{ format(getWinningBid(company['@id']).amount) }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </RoomComponent>
  </div>
</template>

<script>
export default {
  methods: {
    format(value) {
      return new Intl.NumberFormat('en-US').format(value)
    },
  },
}
</script>