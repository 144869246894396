<script setup>
import { Cog8ToothIcon, ChartBarIcon, PaperAirplaneIcon, BuildingOffice2Icon } from '@heroicons/vue/24/outline'
</script>

<template>
  <div class="menu-btn-wrapper flex text-center">
<!--    <button :class="'w-1/5 btn-profile ' + (room !== 'profile' ? '' : 'active')" @click="changeRoom('profile')">Profile</button>-->
    <button :class="'relative w-1/4 btn-airport ' + (room !== 'airport' ? '' : 'active')" @click="changeRoom('airport')">
      <img :src="(room !== 'airport' ? require('../assets/buttons/btn-bg.png') : require('../assets/buttons/btn-bg-active.png'))" alt="Airport">
      <span class="absolute block top-0 left-0 w-full h-full">
        <PaperAirplaneIcon />
        Airport
      </span>
    </button>
    <button :class="'relative w-1/4 btn-investment_bank ' + (room !== 'city' ? '' : 'active')" @click="changeRoom('city')">
      <img :src="(room !== 'city' ? require('../assets/buttons/btn-bg.png') : require('../assets/buttons/btn-bg-active.png'))" alt="City">
      <span class="absolute block top-0 left-0 w-full h-full">
        <BuildingOffice2Icon />
        {{ city(user.currentCity) ? city(user.currentCity).name : '' }}
      </span>
    </button>
    <button :class="'relative w-1/4 btn-portfolio ' + (room !== 'portfolio' ? '' : 'active')" @click="changeRoom('portfolio')">
      <img :src="(room !== 'portfolio' ? require('../assets/buttons/btn-bg.png') : require('../assets/buttons/btn-bg-active.png'))" alt="Portfolio">
      <span class="absolute block top-0 left-0 w-full h-full">
        <ChartBarIcon />
        Portfolio
      </span>
    </button>
    <button :class="'relative w-1/4 btn-settings ' + (room !== 'settings' ? '' : 'active')" @click="changeRoom('settings')">
      <img :src="(room !== 'settings' ? require('../assets/buttons/btn-bg.png') : require('../assets/buttons/btn-bg-active.png'))" alt="Settings">
      <span class="absolute block top-0 left-0 w-full h-full">
        <Cog8ToothIcon />
        Settings
      </span>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', {
      user: 'getUser',
    }),
    ...mapGetters('room', {
      room: 'currentRoom',
    }),
    ...mapGetters('city', {
      city: 'getCity',
    }),
  },
  methods: {
    ...mapActions('room', [
        'changeRoom'
    ]),
  },
}
</script>

<style>
.menu-btn-wrapper button {
  color: #ffffff;
  font-weight: bolder;
  text-transform: uppercase;
  text-shadow: #2B2B2C 2px 2px 10px;
}

.menu-btn-wrapper button span {
  padding-top: 10px;
  font-size: 12px;
}

.menu-btn-wrapper button svg {
  margin: auto;
  width: 60px;
  padding-bottom: 6px
}

@media(min-width:768px) {
  .menu-btn-wrapper button span {
    padding-top: 32px;
    font-size: 16px;
  }

  .menu-btn-wrapper button svg {
    padding-bottom: 30px
  }
}
</style>