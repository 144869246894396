const state = () => ({
  user: {},
  token: null,
})

// getters
const getters = {
  getUser: (state) => state.user,
  getToken: (state) => state.token,
  getBidsCount: (state) => state.user.bids ? state.user.bids.length : 0,
}

// actions
const actions = {
  async login({ commit }) {
    try {
      const data = await fetch(
        process.env.VUE_APP_API_URL + "/auth", {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: 'dani@azulai.nl', password: 'test1234' }),
        }
      )
      const result = await data.json()
      commit("setToken", result.token)
    } catch (error) {
      console.log(error)
    }
  },
  async fetchUser({ commit, state }) {
    try {
      const data = await fetch(
        process.env.VUE_APP_API_URL + "/users/@me", {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + state.token,
          },
        }
      )
      const result = await data.json()
      commit("setUser", result)
    } catch (error) {
      console.log(error)
    }
  },
  setCurrentCity({ state, commit, rootGetters }, city) {
    try {
      state.user.currentCity = city
      commit('setBackground', 'cities/' + rootGetters["city/getCity"](city).background, { root: true })
    } catch (error) {
      console.log(error)
    }
  }
}

// mutations
const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    state.token = token;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}