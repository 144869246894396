<script setup>
import { useStore } from 'vuex'
import RoomComponent from './RoomComponent.vue'
const store = useStore()

const status = store.getters['bid/getStatus'];
const company = store.getters['company/getCompany'];
</script>

<template>
  <div class="h-full py-6 md:py-10 px-5 flex flex-col">
    <h1 class="text-5xl text-center mb-2 md:mb-4 font-medium">My bids</h1>
    <RoomComponent>
      <table v-for="bid in user.bids" :key="bid" class="w-full text-left mb-2">
        <tbody>
        <tr>
          <th class="text-xl" colspan="2">{{ company(getBid(bid).company).name }}</th>
        </tr>
        <tr>
          <th class="font-medium w-[50%]">Asking price</th>
          <td>&euro; {{ format(company(getBid(bid).company).requestPrice) }}</td>
        </tr>
        <tr>
          <th class="font-medium w-[50%]">Amount</th>
          <td>&euro; {{ format(getBid(bid).amount) }}</td>
        </tr>
        <tr v-if="getBid(bid).counterOffer">
          <th class="font-medium">Counter offer</th>
          <td>&euro; {{ format(getBid(bid).counterOffer) }}</td>
        </tr>
        <tr v-if="getBid(bid).secondBidAmount">
          <th class="font-medium">Second bid amount</th>
          <td>&euro; {{ format(getBid(bid).secondBidAmount) }}</td>
        </tr>
        <tr v-if="getBid(bid).secondCounterOffer">
          <th class="font-medium">Second counter offer</th>
          <td>&euro; {{ format(getBid(bid).secondCounterOffer) }}</td>
        </tr>
        <tr v-if="getBid(bid).thirdBidAmount">
          <th class="font-medium">Third bid amount</th>
          <td>&euro; {{ format(getBid(bid).thirdBidAmount) }}</td>
        </tr>
        <tr>
          <th class="font-medium">Status</th>
          <td>{{ status(getBid(bid).status) }}</td>
        </tr>
        </tbody>
        <tbody>
        </tbody>
      </table>
    </RoomComponent>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', {
      user: 'getUser',
    }),
    ...mapGetters('bid', {
      getBid: 'getBid',
    }),
  },
  methods: {
    format(value) {
      return new Intl.NumberFormat('en-US').format(value)
    },
  }
}
</script>