const state = () => ({
  airports: [],
})

// getters
const getters = {
  getAirport: (state) => (uri) => state.airports.find(airport => airport['@id'] === uri),
  getAirportByCity: (state) => (uri) =>  {
    return state.airports.find(airport => airport['city'] === uri)
  },
}

// actions
const actions = {
  async fetchAirports({ commit, rootState }) {
    try {
      const data = await fetch(
        process.env.VUE_APP_API_URL + "/airports", {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + rootState.user.token,
          },
        }
      )
      const result = await data.json()
      commit("setAirports", result['hydra:member'])
    } catch (error) {
      console.log(error)
    }
  },
}

// mutations
const mutations = {
  setAirports(state, airports) {
    state.airports = airports
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}