const state = () => ({
  cities: [],
})

// getters
const getters = {
  getCity: (state) => (uri) => state.cities.find(city => city['@id'] === uri)
}

// actions
const actions = {
  async fetchCities({ commit, rootState }) {
    try {
      const data = await fetch(
        process.env.VUE_APP_API_URL + "/cities", {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + rootState.user.token,
          },
        }
      )
      const result = await data.json()
      commit("setCities", result['hydra:member'])
    } catch (error) {
      console.log(error)
    }
  },
}

// mutations
const mutations = {
  setCities(state, cities) {
    state.cities = cities
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}