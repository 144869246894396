const state = () => ({
  tiers: [],
})

// getters
const getters = {
  getTier: (state) => (uri) => state.tiers.find(tier => tier['@id'] === uri),
  getTierByLevel: (state) => (level) => state.tiers.find(tier => tier.level === level),
}

// actions
const actions = {
  async fetchTiers({ commit, rootState }) {
    try {
      const data = await fetch(
        process.env.VUE_APP_API_URL + "/tiers", {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + rootState.user.token,
          },
        }
      )
      const result = await data.json()
      commit("setTiers", result['hydra:member'])
    } catch (error) {
      console.log(error)
    }
  },
}

// mutations
const mutations = {
  setTiers(state, tiers) {
    state.tiers = tiers
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}