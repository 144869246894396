<script setup>
import { useStore } from 'vuex'
import {ref} from "vue"
import RoomComponent from './RoomComponent.vue'
const store = useStore()

const bid = store.getters['bid/getActiveBidForCompany'];
const status = store.getters['bid/getStatus'];
const company = store.getters['investmentBank/getSelectedCompany'];
let activeBid = ref(bid(company['@id']))
let bidAmount = company.requestPrice
let refreshInterval = null

const refreshBid = async () => {
  await store.dispatch('bid/fetchBid', activeBid.value.id).then((resp) => {
    if (activeBid.value.status !== resp.status) {
      activeBid.value = resp
      store.dispatch('bid/fetchBids')
      refreshInterval = null;
    }
  })
}
const submitBid = async (companyId, amount) => {
  await store.dispatch('investmentBank/submitBid', { companyId: companyId, amount: amount}).then((resp) => {
    activeBid.value = resp
    if (!refreshInterval) {
      refreshInterval = setInterval(function () {
        refreshBid();
      }, 20000)
    }
  });
  await store.dispatch('bid/fetchBids')
}

const submitSecondBid = async (bidId, companyId, amount) => {
  await store.dispatch('investmentBank/submitSecondBid', { bidId: bidId, amount: amount}).then(resp => {
    activeBid.value = resp
    if (!refreshInterval) {
      refreshInterval = setInterval(function () {
        refreshBid();
      }, 20000)
    }
  });
  await store.dispatch('bid/fetchBids')
}
const submitThirdBid = async (bidId, companyId, amount) => {
  await store.dispatch('investmentBank/submitThirdBid', { bidId: bidId, amount: amount}).then(resp => {
    activeBid.value = resp
    if (!refreshInterval) {
      refreshInterval = setInterval(function () {
        refreshBid();
      }, 20000)
    }
  });
  await store.dispatch('bid/fetchBids')
}

if (activeBid.value) {
  refreshInterval = setInterval(function () {
    refreshBid();
  }, 20000)
}
</script>

<template>
  <div class="h-full py-6 md:py-10 px-5 flex flex-col">
    <h1 class="text-4xl text-center mb-2 md:mb-4 font-medium">{{ company.name }}</h1>
    <RoomComponent>
      <div class="flex flex-col h-full">
        <div class="flex-grow">
          <table class="w-full text-left text-sm font-medium mb-2">
            <tbody>
            <tr>
              <td class="w-[33%]">Industry</td>
              <td>{{ industry(company.industry).name }}</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>{{ company.description }}</td>
            </tr>
            <tr>
              <td>Years active</td>
              <td>8 years</td>
            </tr>
            <tr>
              <td>Asking price</td>
              <td>&euro; {{ format(company.requestPrice) }}</td>
            </tr>
            </tbody>
          </table>

          <table class="w-full text-left text-sm font-medium mb-2">
            <tbody>
            <tr>
              <td>Revenue</td>
              <td class="text-right">150,000,000</td>
            </tr>
            <tr>
              <td class="border-b-2 border-black">Cost of revenue</td>
              <td class="border-b-2 border-black text-right">-75,000,000</td>
            </tr>
            <tr>
              <td>Gross profit</td>
              <td class="text-right">75,000,000</td>
            </tr>
            <tr>
              <td class="border-b-2 border-black">Administrative expenses</td>
              <td class="border-b-2 border-black text-right">-45,000,000</td>
            </tr>
            <tr>
              <td>Operating profit</td>
              <td class="text-right">30,000,000</td>
            </tr>
            <tr>
              <td>Interest expenses</td>
              <td class="text-right">-5,000,000</td>
            </tr>
            <tr>
              <td class="border-b-2 border-black">Taxation</td>
              <td class="border-b-2 border-black text-right">-7,500,000</td>
            </tr>
            <tr>
              <td>Net profit</td>
              <td class="text-right">17,500,000</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div v-if="bidsForCompany(company['@id']).length">
          <table class="w-full mb-2 text-left">
            <thead>
            <tr>
              <th>Amount</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="bid in bidsForCompany(company['@id'])" :key="bid">
              <template v-if="!activeBid || activeBid.id !== bid.id">
                <tr>
                  <td class="font-medium">&euro; {{ format(getCurrentAmount(bid)) }}</td>
                  <td class="font-medium">
                    {{ status(bid.status) }}<br>
                    <em v-if="bid.status === 3">
                    &euro; {{ format(bid.counterOffer) }}
                    </em>
                    <em v-if="bid.status === 5">
                    &euro; {{ format(bid.secondCounterOffer) }}
                    </em>
                  </td>
                </tr>
              </template>
            </template>
            </tbody>
          </table>
        </div>

        <div class="text-xl font-medium mb-2" v-if="activeBid">
          Current bid: &euro;
          <span v-if="activeBid.status < 4">
          {{ format(activeBid.amount) }}
          </span>
          <span v-else-if="activeBid.status < 6">
          {{ format(activeBid.secondBidAmount) }}
          </span>
          <span v-else-if="activeBid.status < 8">
          {{ format(activeBid.thirdBidAmount) }}
          </span>
          <br>
          Status: {{ status(activeBid.status) }}<br>
          <span v-if="activeBid.status === 3">
            Amount: &euro; {{ format(activeBid.counterOffer) }}
          </span>
          <span v-if="activeBid.status === 5">
            Amount: &euro; {{ format(activeBid.secondCounterOffer) }}
          </span>
        </div>

        <div v-if="!activeBid || activeBid.status === 3 || activeBid.status === 5" class="text-center">
          <input v-if="!activeBid || activeBid.status === 3 || activeBid.status === 5" type="text" class="w-full h-8 p-2 outline-0 text-right" v-model="bidAmount">
          <button v-if="!activeBid" class="my-2" @click="submitBid(company.id, bidAmount)">
            <span>Submit opening bid</span>
          </button>
          <button v-else-if="activeBid.status === 3" class="my-2" @click="submitSecondBid(activeBid.id, company.id, bidAmount)">
            <span>Submit second bid</span>
          </button>
          <button v-else-if="activeBid.status === 5" class="my-2" @click="submitThirdBid(activeBid.id, company.id, bidAmount)">
            <span>Submit third bid</span>
          </button>
        </div>
      </div>
    </RoomComponent>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters('industry', {
      industry: 'getIndustry',
    }),
    ...mapGetters('bid', {
      bidsForCompany: 'getBidsForCompany',
      getCurrentAmount: 'getCurrentAmount',
    }),
  },
  methods: {
    ...mapActions('room', [
      'changeRoom',
    ]),
    format(value) {
      return new Intl.NumberFormat('en-US').format(value)
    },
  },
}
</script>