import { createStore } from 'vuex'
import airport from './modules/airport'
import city from './modules/city'
import room from './modules/room'
import user from './modules/user'
import tier from './modules/tier'
import bid from './modules/bid'
import company from './modules/company'
import industry from './modules/industry'
import investmentBank from './modules/investmentBank'

export default createStore({
  state: {
    background: null,
    backgrounds: [],
  },
  getters: {
    getBackground: (state) => state.background,
  },
  mutations: {
    setBackground(state, background) {
      state.background = background
      // state.backgrounds.push(background)
    },
  },
  actions: {
  },
  modules: {
    airport,
    city,
    room,
    user,
    investmentBank,
    tier,
    bid,
    company,
    industry,
  }
})
