const state = () => ({
  investmentBanks: [],
  currentInvestmentBank: null,
  currentInvestmentBankCompanies: [],
  selectedCompany: {},
})

// getters
const getters = {
  getInvestmentBank: (state) => (uri) => state.investmentBanks.find(investmentBank => investmentBank['@id'] === uri),
  getInvestmentBanksByCity: (state) => (uri) =>  {
    return state.investmentBanks.filter(airport => airport['city'] === uri)
  },
  getCurrentInvestmentBank: (state) => state.currentInvestmentBank,
  getCurrentInvestmentBankCompanies: (state) => state.currentInvestmentBankCompanies,
  getSelectedCompany: (state) => state.selectedCompany,
}

// actions
const actions = {
  async fetchInvestmentBanks({ commit, rootState }) {
    try {
      const data = await fetch(
        process.env.VUE_APP_API_URL + "/investment_banks", {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + rootState.user.token,
          },
        }
      )
      const result = await data.json()
      commit("setInvestmentBanks", result['hydra:member'])
    } catch (error) {
      console.log(error)
    }
  },
  async fetchCompaniesForInvestmentBank({ commit, rootState }, investmentBank) {
    try {
      const data = await fetch(
        process.env.VUE_APP_API_URL + "/investment_banks/" + investmentBank + '/companies', {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + rootState.user.token,
          },
        }
      )
      const result = await data.json()
      commit("setInvestmentBankCompanies", result['hydra:member'])
      return result['hydra:member']
    } catch (error) {
      console.log(error)
    }
  },
  async submitBid({ rootState }, { companyId, amount }) {
    try {
      const data = await fetch(
        process.env.VUE_APP_API_URL + '/bids', {
          method: "POST",
          body: JSON.stringify({
            user: rootState.user.user['@id'],
            company: "/companies/"+companyId,
            amount: parseInt(amount),
            status: 1,
          }),
          headers: {
            "Content-Type": "application/ld+json",
            "Authorization": "Bearer " + rootState.user.token,
          },
        }
      )
      return await data.json()
    } catch (error) {
      console.log(error)
    }
  },
  async submitSecondBid({ rootState }, { bidId, amount }) {
    try {
      const data = await fetch(
        process.env.VUE_APP_API_URL + '/bids/' + bidId, {
          method: "PATCH",
          body: JSON.stringify({
            secondBidAmount: parseInt(amount),
            status: 4,
          }),
          headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": "Bearer " + rootState.user.token,
          },
        }
      )
      return await data.json()
    } catch (error) {
      console.log(error)
    }
  },
  async submitThirdBid({ rootState }, { bidId, amount }) {
    try {
      const data = await fetch(
        process.env.VUE_APP_API_URL + '/bids/' + bidId, {
          method: "PATCH",
          body: JSON.stringify({
            thirdBidAmount: parseInt(amount),
            status: 6,
          }),
          headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": "Bearer " + rootState.user.token,
          },
        }
      )
      return await data.json()
    } catch (error) {
      console.log(error)
    }
  },
}

// mutations
const mutations = {
  setInvestmentBanks(state, investmentBanks) {
    state.investmentBanks = investmentBanks
  },
  setCurrentInvestmentBank(state, investmentBank) {
    state.currentInvestmentBank = investmentBank
  },
  setInvestmentBankCompanies(state, companies) {
    state.currentInvestmentBankCompanies = companies
  },
  setSelectedCompany(state, company) {
    state.selectedCompany = company
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}