<script setup>
import { useStore } from 'vuex'
import RoomComponent from './RoomComponent.vue'

const store = useStore()
const user = store.getters['user/getUser'];
const tierByLevel = store.getters['tier/getTierByLevel'];
console.log(tierByLevel(1)['@id'], user.tier)
</script>

<template>
  <div class="h-full py-6 md:py-10 px-5 flex flex-col">
    <h1 class="text-5xl text-center mb-2 md:mb-4 font-medium">Tiers</h1>
    <RoomComponent>
      <div class="tiers flex flex-col items-center">
        <div :class="`relative tier1 mb-2 flex ` + (tierByLevel(1)['@id'] === user.tier ? `currentTier` : ``)">
          <span class="top flex flex-col h-full justify-center items-center">
            <span>Tier 1</span>
          </span>
          <span class="absolute bottom-0 left-0 right-0 font-normal">Multinational</span>
        </div>
        <div :class="`relative tier2 mb-2 flex ` + (tierByLevel(2)['@id'] === user.tier ? `currentTier` : ``)">
          <span class="inline-block left-corner"></span>
          <span class="middle-content flex h-full justify-center items-center">
            <span>Tier 2</span>
          </span>
          <span class="inline-block right-corner"></span>
          <span class="absolute bottom-0 left-0 right-0 font-normal">Corporate</span>
        </div>
        <div :class="`relative tier3 mb-2 flex ` + (tierByLevel(3)['@id'] === user.tier ? `currentTier` : ``)">
          <span class="inline-block left-corner"></span>
          <span class="middle-content flex h-full justify-center items-center">
            <span>Tier 3</span>
          </span>
          <span class="inline-block right-corner"></span>
          <span class="absolute bottom-0 left-0 right-0 font-normal">Large</span>
        </div>
        <div :class="`relative tier4 mb-2 flex ` + (tierByLevel(4)['@id'] === user.tier ? `currentTier` : ``)">
          <span class="inline-block left-corner"></span>
          <span class="middle-content flex h-full justify-center items-center">
            <span>Tier 4</span>
          </span>
          <span class="inline-block right-corner"></span>
          <span class="absolute bottom-0 left-0 right-0 font-normal">Medium</span>
        </div>
      </div>
    </RoomComponent>
  </div>
</template>

<style scoped>
.tiers {
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.tiers .top {
  width: 100px;
  height: 80px;
  background: #f3f3f3;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.tiers .left-corner {
  width: 40px;
  height: 60px;
  display: inline-block;
  background: linear-gradient(to bottom right, transparent 0%, transparent 50%, #f3f3f3 50%, #f3f3f3 100%);
}
.tiers .right-corner {
  width: 40px;
  height: 60px;
  display: inline-block;
  background: linear-gradient(to bottom left, transparent 0%, transparent 50%, #f3f3f3 50%, #f3f3f3 0%);
}
.tiers .middle-content {
  height: 60px;
  background: #f3f3f3;
}

.tier2 .middle-content {
  width: 104px;
}
.tier3 .middle-content {
  width: 188px;
}
.tier4 .middle-content {
  width: 272px;
}

.tiers .currentTier .top {
  background: #8ac1e8;
}
.tiers .currentTier .left-corner {
  background: linear-gradient(to bottom right, transparent 0%, transparent 50%, #8ac1e8 50%, #8ac1e8 100%);
}
.tiers .currentTier .right-corner {
  background: linear-gradient(to bottom left, transparent 0%, transparent 50%, #8ac1e8 50%, #8ac1e8 0%);
}
.tiers .currentTier .middle-content {
  background: #8ac1e8;
}
</style>