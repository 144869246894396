<script setup>
import { UserIcon, BanknotesIcon, BuildingOffice2Icon, ChevronDoubleUpIcon }
  from '@heroicons/vue/24/outline'
</script>

<template>
  <div class="top-bar flex justify-between items-center pt-2 pb-5 px-2 bg-gray-100">
    <button class="text-sm text-white font-medium" @click="changeRoom('profile')"><UserIcon/> {{ user.nickname }}</button>
    <button class="text-sm text-white font-bold" @click="changeRoom('my-bids')"><BuildingOffice2Icon />Bids: {{ bids }}</button>
    <button class="text-sm text-white font-bold" @click="changeRoom('tiers')"><ChevronDoubleUpIcon />Tier {{ tier(user.tier).level }}</button>
    <button class="text-sm text-white font-medium" @click="changeRoom('ledger')"><BanknotesIcon/> &euro; {{ cash }}</button>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters('user', {
      bids: 'getBidsCount',
      user: 'getUser',
    }),
    ...mapGetters('tier', {
      tier: 'getTier',
    }),
    cash() {
      return new Intl.NumberFormat('en-US').format(this.user.cash)
    }
  },
  methods: {
    ...mapActions('room', [
      'changeRoom'
    ]),
  },
}
</script>

<style scoped>
.top-bar {
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,0) 25%, rgba(168,168,168,1) 75%);
}

button {
  display: flex;

  svg {
    color: #fff;
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
}
</style>