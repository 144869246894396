<script setup>
import { useStore } from 'vuex'
import RoomComponent from './RoomComponent.vue'
const store = useStore()
const user = store.getters['user/getUser']
</script>

<template>
  <div class="h-full py-6 md:py-10 px-5 flex flex-col">
    <h1 class="text-5xl text-center mb-2 md:mb-4 font-medium">Profile</h1>
    <RoomComponent>
      <div class="flex mb-2">
        <img src="./../assets/avatar-placeholder.png" class="w-[140px]" alt="Avatar">
        <div class="ml-4 flex-grow">
          <input type="text" class="w-full" v-model="user.nickname">
        </div>
      </div>

      <div class="overflow-auto">
        {{ store.getters['user/getToken'] }}
      </div>
    </RoomComponent>
  </div>
</template>
